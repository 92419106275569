/*********************************************
* SASS Mixins
*********************************************/
/**
* Hide the scrollbar until the element is hovered, so keep the page clean
* Use this sparingly because it's not a great UX pattern.
*/
@mixin scrollbar-on-hover() {
  &:not(:hover) {
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
  }
}

/**
 * The PyData Sphinx Theme box shadow rule
 * Copied here in csae we need to re-use.
*/
@mixin pst-box-shadow() {
  box-shadow:
    0 0.2rem 0.5rem var(--pst-color-shadow),
    0 0 0.0625rem var(--pst-color-shadow) !important;
}
