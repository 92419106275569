/*********************************************
* Basic text formatting and content structure *
*********************************************/

.bd-article-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  p.caption {
    color: var(--pst-color-muted);
  }

  // Top two headers are slightly bolder
  h1,
  h2 {
    font-weight: 500;
  }
}

// counteracting pydata style on a::before, for citation style
a.brackets::before {
  color: inherit;
  font-family: inherit;
  margin-right: 0rem;
}

table {
  position: relative;
}
