/**
 * Icon links design for the primary sidebar, where it defaults in this theme.
 */
.bd-sidebar-primary {
  .navbar-icon-links {
    column-gap: 0.5rem;

    .nav-link {
      // There are few kinds of elements that can be icon links and each is different
      i,
      span {
        font-size: 1.2rem;
      }

      // Images usually fill more vertical space so we make them a bit smaller
      img {
        font-size: 0.8rem;
      }
    }
  }
}
