/**
 * Comment JavaScript libraries
  * Libraries supported here are:
  * - Hypothes.is : https://web.hypothes.is/
  * - Utteranc.es : https://utteranc.es/
  */
// In general we want to hide any comment libraries on print
@media only print {
  hypothesis-sidebar,
  div.utterances {
    display: none;
  }
}
