// MyST-NB and Jupyter Notebooks
div.cell.tag_output_scroll div.cell_output,
div.cell.tag_scroll-output div.cell_output {
  max-height: 24em;
  overflow-y: auto;
}

div.cell.tag_scroll-input div.cell_input {
  max-height: 24em;
  overflow-y: auto;
}
