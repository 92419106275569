// Images
img {
  max-width: 100%;

  &.align-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &.align-left {
    clear: left;
    float: left;
    margin-right: 1em;
  }

  &.align-right {
    clear: right;
    float: right;
    margin-left: 1em;
  }
}

// Figures
div.figure {
  width: 100%;
  margin-bottom: 1em;
  text-align: center;

  &.align-left {
    text-align: left;
    p.caption {
      margin-left: 0;
    }
  }
  &.align-right {
    text-align: right;
    p.caption {
      margin-right: 0;
    }
  }

  p.caption {
    margin: 0.5em 10%;
  }

  &.margin,
  &.margin-caption {
    p.caption {
      margin: 0.5em 0;
    }
  }

  &.margin-caption p.caption {
    text-align: left;
  }

  span.caption-number {
    font-weight: bold;
  }

  span {
    font-size: 0.9rem;
  }
}
