/*********************************************
* Print-specific CSS *
*********************************************/
@media print {
  .bd-main {
    .bd-content {
      margin-left: 2rem;
      height: auto;
      // Structural elements
      #jb-print-docs-body {
        margin-left: 0rem;

        h1 {
          font-size: 3em;
          text-align: center;
          margin-bottom: 0;
        }
      }
      // Main content adjustments
      .bd-article {
        padding-top: 0;

        // The first H1 is the title, we've already displayed above
        h1:first-of-type {
          display: none;
        }
      }

      // HACK: Without this, some code cells take the whole width
      .container {
        min-width: 0% !important;
      }

      // Content
      h1 {
        margin-top: 1em;
        margin-bottom: 1em;
      }
      h1,
      h2,
      h3,
      h4 {
        break-after: avoid;
        color: black;
      }

      table {
        break-inside: avoid;
      }

      pre {
        word-wrap: break-word;
      }

      a.headerlink {
        display: none;
      }

      // Remove borders to save some ink
      blockquote.epigraph,
      aside.margin,
      aside.sidebar {
        border: none;
      }

      .footer {
        margin-top: 1em;
      }
      // Print-only table of contents
      #jb-print-toc {
        margin-bottom: 1.5rem;
        margin-left: 0rem;
        .section-nav {
          border-left: 0px !important;
          list-style-type: disc !important;
          margin-left: 3em !important;
          a {
            text-decoration: none !important;
          }
          li {
            display: list-item !important;
          }
          .nav {
            display: none;
          }
        }
      }
    }

    // Hide the footer on printing
    .bd-footer-content {
      display: none !important;
    }
  }
}
