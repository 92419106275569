.bd-header {
  // Turn off sticky positioning so that it scrolls
  position: inherit;

  // Turn these off because we'll show it in the article header
  // The primary sidebar toggle
  button.sidebar-toggle {
    display: none;
  }
}
