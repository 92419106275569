#pst-back-to-top {
  // We should only display the back-to-top button when the TOC is not available.
  // AKA, on narrow-ish screens.
  // This over-rides a PST default
  @media (min-width: $breakpoint-xl) {
    display: none !important;
  }

  // Reduce size to be slightly less intrusive on the page
  font-size: 0.8rem;
}
